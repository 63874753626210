<template>
<div>
  <v-row>
    <v-col md="6"
        lg="12">
      <DashboardStatusRoom :rp="repport"></DashboardStatusRoom>
    </v-col>
  </v-row>
  <v-row>
    <v-col
        cols="12"
        md="12"
    >
      <span class="font-weight-semibold mt-3 mb-3">Recette Hebdomadaire par type de chambre (FCFA)</span>
      <v-spacer></v-spacer>
      <RoomPieChart></RoomPieChart>
    </v-col>
  </v-row>
  <v-row>
    <v-col
        cols="12"
        md="12"
    >
      <RoomLineChart></RoomLineChart>
    </v-col>
  </v-row>
  <v-row>
    <v-col
        cols="12"
        md="12"
    >
      <span class="font-weight-semibold mt-3 mb-3">Point de caisse hebdomadaire (montant total : {{repport1}} FCFA)</span>
      <StayLineChart></StayLineChart>
    </v-col>
  </v-row>
</div>
</template>

<script>
// eslint-disable-next-line import/extensions
import RoomPieChart from './RoomPieChart'
// eslint-disable-next-line import/extensions
import RoomLineChart from './RoomLineChart'
// eslint-disable-next-line import/extensions
import StayLineChart from './StayLineChart'
// eslint-disable-next-line import/extensions,import/no-unresolved

import DashboardStatusRoom from '@/views/dashboard/DashboardStatusRoom'

export default {
  name: 'HomeDashboard',
  components: {
    DashboardStatusRoom,
    RoomPieChart,
    RoomLineChart,
    StayLineChart,
  },
  data() {
    return {
      repport: {},
      repport1: 0,
      totalProfit: {
        statTitle: 'Personnels',
        icon: this.mdiChartBar,
        color: 'success',
        subtitle: 'Hebdomadire',
        statistics: '$25.6k',
        change: '+42%',
      },
    }
  },
  mounted() {
    this.Repport()
    // eslint-disable-next-line no-self-assign
    // setTimeout(() => this.repport = this.repport1, 2500)
  },
  methods: {
    async Repport() {
      await this.$axios.get(this.$endpoint.HomeRepport).then(rp => {
        // eslint-disable-next-line no-return-assign
        localStorage.setItem('dataHomeRepport', JSON.stringify(rp.data))
        this.repport1 =rp.data.stay_hebdo.map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
        // console.log(this.repport)
      })
    },
  },
}

</script>

<style scoped>

</style>
