<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Resumer de la semaine en cours</span>
      <v-spacer></v-spacer>
      <v-btn
          icon
          small
          class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <!--    <v-card-subtitle class="mb-8 mt-n5">-->
    <!--      <span class="font-weight-semibold text&#45;&#45;primary me-1">Total 48.5% Growth</span>-->
    <!--      <span>😎 this month</span>-->
    <!--    </v-card-subtitle>-->

    <v-card-text>
      <v-row>
        <v-col
            v-for="data in statisticsData"
            :key="data.title"
            cols="6"
            md="3"
            class="d-flex align-center"
        >
          <v-avatar
              size="44"
              :color="resolveStatisticsIconVariation (data.title).color"
              rounded
              class="elevation-1"
          >
            <v-icon
                dark
                color="white"
                size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'

export default {
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Reccette') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Clients') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Sejours') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Versement') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
  data() {
    return {
      rp: {},
      statisticsData: [
        {
          title: 'Reccette',
          total: 0,
        },
        {
          title: 'Clients',
          total: 0,
        },
        {
          title: 'Sejours',
          total: 0,
        },
        {
          title: 'Versement',
          total: 0,
        },
      ],
    }
  },
  mounted() {
    //console.log(this.rp)
    setTimeout(()=> {
      this.repportHome()
    }, 1000)
  },
  methods: {
    repportHome() {
      const rp = JSON.parse(localStorage.getItem('dataHomeRepport'))
      this.statisticsData[0].total = rp.fund_1000.fund_amount
      this.statisticsData[1].total = rp.total_client
      this.statisticsData[2].total = rp.stay_hebdo.length
      this.statisticsData[3].total = rp.payment.length
    },
  },
}
</script>
