<script>
import { Pie } from 'vue-chartjs'

export default {
  name: 'RoomPieChart',
  extends: Pie,
  data() {
    return {
      chartData: {
        labels: ['Italy', 'India', 'Japan', 'USA'],
        datasets: [{
          borderWidth: 1,
          borderColor: [
            'rgb(255,99,132,1)',
            'rgb(54, 162, 235, 1)',
            'rgb(255, 206, 86, 1)',
            'rgb(75, 192, 192, 1)',
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          // eslint-disable-next-line no-tabs
          data: [1000,	500,	1500],
          hoverOffset: 4,

        }],
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      stayInvoice:[],
      roomType: []
    }
  },
  mounted() {
    setTimeout(()=> {
      this.loadRoomStay()
      this.chartData.labels = this.roomType
      this.renderChart(this.chartData, this.options)
    }, 1000)
  },
  methods: {
    loadRoomStay() {
      this.stayInvoice = JSON.parse(localStorage.getItem('dataHomeRepport')).stay_hebdo
      const rmtp = JSON.parse(localStorage.getItem('dataHomeRepport')).room_type
      rmtp.forEach((rtp)=>{
        this.roomType.push(rtp.room_type_name)
      })
      let americain, deux_piece, trois_piece = []
      americain = this.stayInvoice.filter(am => am.room.room_type_id === rmtp[0].id)
      deux_piece = this.stayInvoice.filter(am => am.room.room_type_id === rmtp[1].id)
      trois_piece = this.stayInvoice.filter(am => am.room.room_type_id === rmtp[2].id)
      // console.log(americain[0].stays_total_price_to_pay)
      // console.log(americain.map(item => item.stays_total_price_to_pay).reduce((pre,next)=>pre+next))

      this.chartData.datasets[0].data[0]= americain.map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
      this.chartData.datasets[0].data[1]= deux_piece.map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
      this.chartData.datasets[0].data[2]= trois_piece.map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
    },
  },
}
</script>

<style scoped>

</style>
