
<script>
import { Line } from 'vue-chartjs'
export default {
  name: "StayLineChart",
  extends: Line,
  data () {
    return {
      chartData: {
        labels: ["Babol",	"Cabanatuan",	"Daegu",	"Jerusalem",	"Fairfield",	"New York",	"Gangtok", "Buenos Aires", "Hafar Al-Batin", "Idlib"],
        datasets: [
          {
            label: 'Montant total de la recette hebdomadaire ',
            data: [600,	1150,	342,	6050,	2522,	3241,	1259,	157,	1545, 9841],
            fill: false,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [ {
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    setTimeout(()=> {
      this.generateStayRecord()
      this.renderChart(this.chartData, this.options)
    },1000)
  },
  methods: {
    generateStayRecord(){
      const stays= JSON.parse(localStorage.getItem('dataHomeRepport')).stay_hebdo
      const start_week = JSON.parse(localStorage.getItem('dataHomeRepport')).start_week
      const end_week = JSON.parse(localStorage.getItem('dataHomeRepport')).end_week
      const start_date = new Date(start_week)
      const end_date = new Date(end_week)
      // console.log(start_date.getDate() , end_date.getDate())
      const label =[]
      const datas=[]
      for (let e =start_date.getDate(); e <= end_date.getDate(); e++){
        label.push(start_date.toLocaleDateString('fr-FR'))
        datas.push(stays.filter(item =>
            new Date(item.created_at).toLocaleDateString('fr-FR')
            ==
            start_date.toLocaleDateString('fr-FR')).map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0))
        start_date.setDate(start_date.getDate() + 1)
      }
      this.chartData.labels = label
      this.chartData.datasets[0].data = datas
    }

  }
}
</script>

<style scoped>

</style>
